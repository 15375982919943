import {currentLocale} from '../../helpers/locales';
import {__} from '../../translations';

export {
    formatLocalDate,
    formatLocalTime,
    formatUtcDate as formatDate,
    formatUtcTime as formatTime,
    formatUtcDateTime as formatDateTime
} from '../../util/dates';

export const formatRelativeTime = (timeDifferenceMillis: number): string => {
    const seconds = Math.round(timeDifferenceMillis / 1000);
    const minutes = Math.round(seconds / 60);
    const hours = Math.round(minutes / 60);
    const days = Math.round(hours / 24);

    try {
        const rtf = new Intl.RelativeTimeFormat(currentLocale!.iso, {
            localeMatcher: 'best fit',
            numeric: 'always',
            style: 'long'
        });

        return Math.abs(days)
            ? rtf.format(days, 'days')
            : Math.abs(hours)
                ? rtf.format(hours, 'hours')
                : Math.abs(minutes) ? rtf.format(minutes, 'minutes') : rtf.format(seconds, 'seconds');
    } catch {
        return `${hours} ${__('hours ago')}`;
    }
};

export const formatTimeDifference = (timeFrom: number, timeTo:number) : string => {
    const timeDifferenceMillis = (timeTo - timeFrom);

    const date = new Date(timeFrom);
    const hours = date.getHours().toString().replace(/^(\d)$/, '0$1');
    const minutes = date.getMinutes().toString().replace(/^(\d)$/, '0$1');

    const days = Math.floor(timeDifferenceMillis / 1000 / 60 / 60 / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years) {
        return years > 1 ? `${years} ${__('years ago')}` : `${years} ${__('year ago')}`;
    } else if (months) {
        return months > 1 ? `${months} ${__('months ago')}` : `${months} ${__('month ago')}`;
    } else if (weeks) {
        return weeks > 1 ? `${weeks} ${__('weeks ago')}` : `${weeks} ${__('week ago')}`;
    } else if (days) {
        return days > 1 ? `${days} ${__('days ago')}` : `${__('Yesterday')}, ${hours}:${minutes}`;
    }

    return `${__('Today')}, ${hours}:${minutes}`;
};

export const day = 1000 * 60 * 60 * 24;
export const week = day * 7;
export const month = day * 30;
export const year = day * 365;

export const toMilli = (time: string): number => {
    const minute = 1000 * 60;
    const hours = Number(time.substring(0, 2)) * 60 * minute;
    const minutes = Number(time.substring(3, 5)) * minute;
    return hours + minutes;
};

export const toNumberAndPeriod = (timeDifference: number) => {
    const day = 1000 * 60 * 60 * 24;
    const days = timeDifference / day;
    let number;
    let period;
    if (days % 365 === 0) {
        period = 'years';
        number = days / 365;
    } else if (days % 30 === 0) {
        period = 'months';
        number = days / 30;
    } else if (days % 7 === 0) {
        period = 'weeks';
        number = days / 7;
    } else {
        period = 'days';
        number = Math.round(days); // shouldn't be necessary but just in case
    }

    return [`${number}`, period];
};

export const toTime = (timeInMs: number): string => new Intl.DateTimeFormat(undefined, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZone: 'UTC'
}).format(timeInMs);

export const delay = <T>(milliseconds: number, callback?: () => T | Promise<T>): Promise<T | undefined> => new Promise<T | undefined>((resolve, reject) => {
    setTimeout(() => {
        try {
            resolve(callback?.());
        } catch (error) {
            reject(error);
        }
    }, milliseconds);
});

export const animationEnd = (element: Element, maxWaitMillis: number = 60000): Promise<void> => new Promise((resolve) => {
    const handler = () => {
        element.removeEventListener('transitionend', handler);
        element.removeEventListener('animationend', handler);
        resolve();
    };
    element.addEventListener('animationend', handler, {once: true});
    element.addEventListener('transitionend', handler, {once: true});
    delay(maxWaitMillis).then(() => resolve());
});

export const nextTick = (): Promise<void> => delay(0);

export const debounce = <T>(callback: () => T | Promise<T>): Promise<T> => new Promise<T>((resolve, reject) => {
    requestAnimationFrame(() => {
        try {
            resolve(callback());
        } catch (error) {
            reject(error);
        }
    });
});
