import type {FunctionComponent, MouseEvent} from 'react';
import React from 'react';
import {classNames} from '../../../../react/helpers/styling';
import {__} from '../../../../translations';
import {LinkButton} from '../../buttons/LinkButton';

export const SidebarEntry: FunctionComponent<{
    id: string;
    to: string;
    title: string;
    days?: number;
    active: boolean;
    onClick?: (event: MouseEvent<HTMLLinkElement>) => void;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}> = ({id, to, title, Icon, onClick, days, active}) => (
    <LinkButton
        id={id} key={to !== '#' ? to : id} to={to} onClick={onClick} className={classNames('sidebar__entry', {
            yellow: !!days,
            red: days === 0,
            active
        }, (/(plannings|report|statistics)/).test(id) ? 'sidebar__entry__divider' : '')}>
        <Icon className="sidebar__entry__icon"/>
        <span className="sidebar__entry__link">{title}</span>
        {days !== undefined
            ? (
                <>
                    <span className="sidebar__entry__daynumber">{days}</span>
                    <span className="sidebar__entry__dayname">{days === 1 ? __('Day') : __('Days')} {__('Left')} </span>
                </>
            )
            : null}
    </LinkButton>
);

