import type {FunctionComponent} from 'react';
import React, {useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {deleteSession} from '../../../../api/endpoints/sessies/[sessionId]/delete/frontend';
import {deleteSessions} from '../../../../api/endpoints/sessies/delete/frontend';
import {environmentAllowsPayment} from '../../../../helpers/AppEnvironment';
import {Image} from '../../../../react/components/Image';
import {Photo} from '../../../../react/components/Photo';
import {Popup} from '../../../../react/components/popups/Popup';
import {Tekst} from '../../../../react/components/Tekst';
import {Checkbox} from '../../../../react/components/toggles/Checkbox';
import {useCurrentSession, useCurrentUser} from '../../../../react/context';
import {useCookie, useMembership, useUser} from '../../../../react/hooks';
import {__} from '../../../../translations';
import {useMenu} from '../../../context/MenuContext';
import ChartIcon from '../../../icons/chart.svg';
import InspectionTemplatesIcon from '../../../icons/checklist.svg';
import SettingsIcon from '../../../icons/cog.svg';
import ExitIcon from '../../../icons/exit.svg';
import InspectionsIcon from '../../../icons/inspection-icon.svg';
import LibrariesIcon from '../../../icons/library.svg';
import OrganisationIcon from '../../../icons/organisation-icon.svg';
import CalendarIcon from '../../../icons/planning-icon.svg';
import ReportTemplatesIcon from '../../../icons/report-icon.svg';
import UpgradeIcon from '../../../icons/Rocket.svg';
import TaskIcon from '../../../icons/Task.svg';
import UsersIcon from '../../../icons/users.svg';
import {SidebarEntry} from './SidebarEntry';
import './Sidebar.scss';

const menuEntries = [
    {
        to: '/inspections',
        id: 'link-sidebar-inspections',
        title: __('Inspections'),
        Icon: InspectionsIcon
    },
    {
        to: '/tasks',
        id: 'link-sidebar-tasks',
        title: __('Tasks'),
        Icon: TaskIcon
    },
    {
        to: '/inspection-plannings',
        id: 'link-sidebar-inspection-plannings',
        title: __('Planning'),
        Icon: CalendarIcon
    },
    {
        to: '/inspection-templates',
        id: 'link-sidebar-inspection-templates',
        title: __('Templates'),
        Icon: InspectionTemplatesIcon
    },
    {
        to: '/report-templates',
        id: 'link-sidebar-report-templates',
        title: __('Report Layouts'),
        Icon: ReportTemplatesIcon
    },
    {
        to: '/libraries',
        id: 'link-sidebar-libraries',
        title: __('Libraries'),
        Icon: LibrariesIcon
    },
    {
        to: '/organisations',
        id: 'link-sidebar-organisations',
        title: __('Organisations'),
        Icon: OrganisationIcon
    },
    {
        to: '/team-members',
        id: 'link-sidebar-team-members',
        title: __('Team Members'),
        Icon: UsersIcon
    },
    {
        to: '/statistics',
        id: 'link-sidebar-statistics',
        title: __('Management dashboard'),
        Icon: ChartIcon
    },
    {
        to: '/settings',
        id: 'link-sidebar-settings',
        title: __('Settings'),
        Icon: SettingsIcon
    },
    {
        to: '/upgrade',
        id: 'link-sidebar-upgrade',
        title: __('Premium plan'),
        Icon: UpgradeIcon
    },
    {
        to: '/logout',
        id: 'link-sidebar-logout',
        title: __('Log out'),
        Icon: ExitIcon
    }
];

export const Sidebar: FunctionComponent = ({children, ...props}) => {
    const [{isSidebarOpen}] = useMenu();
    const history = useHistory();
    const [logoutOnAllDevices, setLogoutOnAllDevices] = useState<boolean>();
    const [showLogoutPopup, setShowLogoutPopup] = useState<boolean>();
    const onLogoutClick = (event) => {
        event.preventDefault();
        setShowLogoutPopup(true);
    };

    const {currentSession: session} = useCurrentSession();
    const {currentUser: authenticatedUser} = useCurrentUser();
    const {user} = useUser(authenticatedUser?._id);
    const {membership} = useMembership();
    const env = useCookie('env');
    const days = membership?.productName === 'trial'
        ? membership.remainingDays
        : membership?.productName === 'free'
            ? 0
            : undefined;
    const location = useLocation();

    return (
        <div {...props} className={`sidebar sidebar--open-${isSidebarOpen ? 'yes' : 'no'}`}>
            {showLogoutPopup
                ? (
                    <Popup
                        confirmButtonText={__('Log out')}
                        titleText={__('Logout')}
                        onCancel={() => setShowLogoutPopup(false)}
                        onClose={() => setShowLogoutPopup(false)}
                        onConfirm={async () => {
                            try {
                                logoutOnAllDevices
                                    ? await deleteSessions({})
                                    : await deleteSession({sessionId: session!._id});
                                history.push('/logout');
                            } catch {
                                alert(__('We could not log you out. Are you offline? Turn on wifi or network and try again.'));
                            }
                        }}>
                        <Checkbox
                            name="Logout-on-all-devices-checkbox"
                            value={logoutOnAllDevices ? 'checked' : 'unchecked'}
                            defaultChecked="unchecked"
                            onChange={(value) => setLogoutOnAllDevices(value === 'checked')}
                        >
                            <Tekst>
                                {__('Logout on all devices')}
                            </Tekst>
                        </Checkbox>
                    </Popup>
                )
                : null}
            <Link to="/team-members/me" className={`sidebar__header sidebar__header--visible-${isSidebarOpen ? 'yes' : 'no'}`}>
                <div className="sidebar__header__photo">
                    {user?.photo_id
                        ? (
                            <Photo
                                cache
                                photo_id={user.photo_id}
                                className="sidebar__header__image"
                                alt={__('Profile Image')}
                            />
                        )
                        : (
                            <Image
                                loading={!user}
                                className="sidebar__header__image"
                                src={user && typeof user.photo === 'object' ? user?.photo?.photoSource : `/workbox-checkbuster/photos/${user?._id}-${user?.photo}`}
                                alt={__('Profile Image')}
                            />
                        )}
                </div>

                <span className="sidebar__header__name">{user?.name || __('Me')}</span>
                <span className="sidebar__header__mail">{authenticatedUser?.email}</span>
            </Link>
            <div className={isSidebarOpen ? 'sidebar__entry-list sidebar__entry-list--scroll' : 'sidebar__entry-list'}>
                {menuEntries.filter((entry) => (entry.id !== 'link-sidebar-upgrade') || (days !== undefined && environmentAllowsPayment(env))).map((entry) => (
                    <SidebarEntry
                        active={location.pathname.startsWith(entry.to)}
                        key={entry.to}
                        days={entry.id === 'link-sidebar-upgrade' ? days : undefined}
                        onClick={entry.id === 'link-sidebar-logout' ? onLogoutClick : undefined}
                        {...entry}
                    />
                ))}
            </div>
        </div>
    );
};
